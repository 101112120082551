<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">仓库单据</el-breadcrumb-item>
        <el-breadcrumb-item>调整报损明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in tabs"
          :label="item.val"
          :key="item.key"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row>
      <breakage-adjust-list :type="tabvalue"></breakage-adjust-list>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import breakageAdjustList from './breakage-adjust-list'
export default {
  components: {
    breakageAdjustList
  },
  data () {
    return {
      tabs: [],
      tabvalue: '5'
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    const FinanceWMSAdjustment = this.sysConfig.FinanceWMSAdjustment
    this.tabs = FinanceWMSAdjustment.type
  }
}
</script>
