<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WarehouseMultSelector',
  // props: {
  //   value: {
  //     type: Array,
  //     default: () => []
  //   }
  // },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    ...mapGetters(['sysConfig'])
  }
}
</script>
<template>
  <el-select v-bind="$attrs" v-on="$listeners" multiple>
    <el-option
      v-for="item in sysConfig.warehouselist"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
