<template>
  <div>
    <el-row class="content-searcha">
      <el-form :inline="true" size="small">
        <el-form-item>
          <WarehouseMultSelector
            v-model="search.warehouseId"
            placeholder="请选择仓库"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchBreakAdjustlist()"
            >搜索</el-button
          >
          <!-- <el-button type="primary" @click="$refs['export-dialog'].show('E05A')"
            >导出下载</el-button
          > -->
          <el-button type="primary" @click="handleExport"
            >导出下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe :data="breakAdjustlist" border width="100%">
        <el-table-column label="仓库单号">
          <template slot-scope="{ row }">
            <p>{{ row.order_id }}</p>
            <el-tag>{{ row.aino }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="warehouse" label="仓库"></el-table-column>
        <!-- <el-table-column prop="user_name" label="仓管"></el-table-column> -->
        <el-table-column prop="updated_at" label="日期"></el-table-column>
        <el-table-column prop="mfn" label="MFN"></el-table-column>
        <el-table-column prop="product" label="品名"></el-table-column>
        <el-table-column label="成本价">
          <template slot-scope="{ row }">
            <span>{{ row.currency }} {{ row.cost }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column prop="cost_amount" label="成本金额"></el-table-column>
        <el-table-column prop="order_no" label="外部单号"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <!-- <export-dialog ref="export-dialog"></export-dialog> -->
    <xlsx-downloader ref="xlsx-downloader" type="FINANCE_BREAKAGE_ADJUSTMENT" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as warehouseBillApi from '@/api/warehouseBill'
import WarehouseMultSelector from '@/components/finance/warehouseBills/WarehouseMultSelector'
import XlsxDownloader from '@/components/xlsx-downloader'
export default {
  components: {
    XlsxDownloader,
    WarehouseMultSelector
  },
  props: ['type'],
  data () {
    return {
      search: {},
      breakAdjustlist: [],
      pageData: {
        current_page: 1,
        per_page: 100
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      this.getbreakageAdjustList()
    }
  },
  created () {
    // this.getbreakageAdjustList()
  },
  methods: {
    handleExport () {
      // type = E05A
      const params = {
        date_start: this.search.start,
        date_end: this.search.end,
        warehouse_id: this.search.warehouseId ? this.search.warehouseId.join(',') : null
      }
      this.$refs['xlsx-downloader'].show(params)
    },
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getbreakageAdjustList () {
      const self = this
      console.log(self.type)
      const listJsonData = {
        type: self.type,
        warehouse_id: self.search.warehouseId,
        date_start: self.search.start,
        date_end: self.search.end,
        page: self.pageData.current_page,
        per_page: self.pageData.per_page
      }
      warehouseBillApi
        .getbreakageAdjustList(listJsonData)
        .then(data => {
          self.breakAdjustlist = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getbreakageAdjustList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getbreakageAdjustList()
    },
    searchBreakAdjustlist () {
      this.pageData.current_page = 1
      this.getbreakageAdjustList()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/wms/exportAdjustment?token=' +
        localStorage.getItem('token') +
        '&type=' +
        this.type
      window.open(url)
    }
  }
}
</script>
